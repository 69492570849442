import jwt from 'jsonwebtoken';
import { Base64 } from 'js-base64';
import getConfig from 'next/config';
import redirect from './redirect';
import { setCookie, getCookie, getCookieJSON, removeCookie } from './session';
import { authenticate, getRefreshToken } from '../services/auth';
import { createUser } from '../services/user';
import { validateCredentials, validateNewUser } from './validation';

const { publicRuntimeConfig } = getConfig();
const { BLACKLIST_SUB, BASE_URL } = publicRuntimeConfig;

export const signIn = async (email, password, redirectPath = '/') => {
  const error = validateCredentials(email, password);
  if (error) {
    return error;
  }

  const res = await authenticate(email, password);
  if (!res.data || !res.data.token) {
    return res;
  }

  setCookie('user', res.data.user);
  setCookie('jwt', res.data.token);
  if (res.data.legacy_session)
    setCookie('PHPSESSID', res.data.legacy_session.PHPSESSID);

  redirect(redirectPath);
  return null;
};

export const signUp = async (
  first_name,
  last_name,
  email,
  password,
  password_confirmation,
) => {
  const error = validateNewUser(
    first_name,
    last_name,
    email,
    password,
    password_confirmation,
  );
  if (error) {
    return error;
  }

  const res = await createUser(
    first_name,
    last_name,
    email,
    password,
    password_confirmation,
  );

  if (!res.data) {
    return res;
  }

  setCookie('success', `${first_name}, your account was created.`);
  redirect('/login');
  return null;
};

export const clearSession = (ctx = {}) => {
  removeCookie('jwt');
  removeCookie('user');
  removeCookie('PHPSESSID');
};

export const signOut = (ctx = {}) => {
  clearSession(ctx);
  redirect('/', ctx);
};

export const getUser = (ctx) => getCookieJSON('user', ctx && ctx.req);
export const getProduct = (ctx) =>
  getCookieJSON('productActive', ctx && ctx.req);

export const getJwt = (ctx) => getCookie('jwt', ctx && ctx.req);

export const isAuthenticated = (ctx) => {
  const token = getJwt(ctx);
  if (token) {
    const decoded_token = jwt.decode(token);
    // console.log('tes', BLACKLIST_SUB[0])
    if (BLACKLIST_SUB.includes(decoded_token.sub)) {
      return false;
    }
    // check token expiry
    if (Date.now() / 1000 > decoded_token.exp) {
      clearSession(ctx);
      return false;
    }
    return true;
  }
  return false;
};

export const redirectIfAuthenticated = (ctx, target = '/') => {
  if (isAuthenticated(ctx)) {
    redirect(target, ctx);
    return true;
  }
  return false;
};

export const redirectIfNotAuthenticated = (ctx, target) => {
  if (!isAuthenticated(ctx)) {
    let redirectPath =
      ctx.asPath != '/'
        ? `https://pro.hukumonline.com${ctx.asPath}`
        : `https://pro.hukumonline.com/${ctx.asPath}`;
    redirectPath = Base64.encode(redirectPath);
    const defaultRedirect = `https://id.hukumonline.com/user/login?returnUrl=${redirectPath}`;
    redirect(target || defaultRedirect, ctx);
    return true;
  }
  return false;
};
export const redirectIfNotAuthenticatedLogin = (ctx, target = '/') => {
  if (!isAuthenticated(ctx)) {
    let redirectPath =
      ctx.asPath != '/'
        ? `${BASE_URL}${ctx.asPath}`
        : `${BASE_URL}${ctx.asPath}`;
    redirectPath = Base64.encode(redirectPath);
    redirect(
      `https://id.hukumonline.com/user/login?returnUrl=${redirectPath}`,
      ctx,
    );
    return true;
  }
  return false;
};

export const refreshToken = async (ctx) => {
  const token = getJwt(ctx);
  const res = await getRefreshToken(token);

  if (!res.data || !res.data.token) {
    return null;
  }
  return res.data.token;
};

export const isPremiumStoriesValidToken = async (token) => {
  const decodedToken = jwt.decode(token);
  const { ups = '[{}]', aud = '', sbx = '' } = decodedToken;
  const parsedToken = JSON.parse(ups);
  const { product = '', end_date = '' } =
    parsedToken[0] != null ? parsedToken[0] : {};
  const currentDate = new Date();
  const endDate = new Date(end_date);
  // premiumPreviewValidityUserProducts=> true:show full content # false: not valid, show preview
  let premiumPreviewValidityUserProducts =
    parsedToken && product === 'premium-stories' && currentDate < endDate;
  const packageProEndDate = new Date(sbx);
  if (
    [
      'member-professional',
      'member-lite',
      'member-professional-plus',
      'staff',
    ].includes(aud) &&
    currentDate < packageProEndDate &&
    premiumPreviewValidityUserProducts === false
  )
    premiumPreviewValidityUserProducts = true;
  return premiumPreviewValidityUserProducts;
};
