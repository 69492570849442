import { post, get } from '../libraries/request';

export const authenticate = async (email, password) => {
  try {
    const res = await post('/v2/auth', {
      email,
      password,
    });
    return res.data;
  } catch (error) {
    return error.response && error.response.status === 404
      ? 'Wrong email/password'
      : 'Unknown error. Please try again';
  }
};

export const getRefreshToken = async (token) => {
  try {
    const res = await get('/v1/users/refresh-token', token);
    return res.data;
  } catch (error) {
    return error;
  }
};
