export const validateNewUser = (
  first_name,
  last_name,
  email,
  password,
  password_confirmation
) => {
  if (!first_name) {
    return "Last Name field is required.";
  }

  if (!last_name) {
    return "Last Name field is required.";
  }

  if (password !== password_confirmation) {
    return "Password and confirmation password must match.";
  }

  return validateCredentials(email, password);
};

export const validateCredentials = (email, password) => {
  if (!email || !password) {
    return "Email and password fields are required.";
  }

  // if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  //   return "Email does not have right format.";
  // }

  // if (!(password.length >= 6)) {
  //   return "Password needs at least 6 characters.";
  // }

  return null;
};
